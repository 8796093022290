import React, { useState, useEffect } from "react";
import "../../assets/css/SearchBox.css";
import searchIcon from "../../assets/svg/IkonSearch.svg";
import { useViewer } from "../../viewerContext";

const SearchBoxComponent = ({ availableBandels }) => {
  const [availableKms, setAvailableKms] = useState([]);
  const [availableMeters, setAvailableMeters] = useState([]);
  const { setViewerState } = useViewer();

  // Values from sessionStorage
  const [bdl, setBdl] = useState(() => sessionStorage.getItem("bdl") || "");
  const [km, setKm] = useState(() => sessionStorage.getItem("km") || "");
  const [m, setM] = useState(() => sessionStorage.getItem("m") || "");

  useEffect(() => {
    if (bdl && availableBandels[bdl]) {
      setAvailableKms(Object.keys(availableBandels[bdl]));
    }
    if (km && availableBandels[bdl] && availableBandels[bdl][km]) {
      setAvailableMeters(availableBandels[bdl][km]);
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.clear(); // Clears sessionStorage when the page is to be reloaded or closed
    });
  }, [bdl, km, availableBandels]);

  // When the user selects a bandel, update the available kilometers
  const handleBdlChange = (e) => {
    const selectedBdl = e.target.value;
    setBdl(selectedBdl);
    sessionStorage.setItem("bdl", selectedBdl); // Save bdl to sessionStorage

    if (availableBandels[selectedBdl]) {
      setAvailableKms(Object.keys(availableBandels[selectedBdl]));
    } else {
      setAvailableKms([]);
    }
  };

  // When the user selects a km, update the available meter
  const handleKmChange = (e) => {
    const selectedKm = e.target.value;
    setKm(selectedKm);
    sessionStorage.setItem("km", selectedKm); // Save to km sessionStorage

    if (availableBandels[bdl] && availableBandels[bdl][selectedKm]) {
      setAvailableMeters(availableBandels[bdl][selectedKm]);
    } else {
      setAvailableMeters([]);
    }
  };

  const handleMChange = (e) => {
    const selectedMeter = e.target.value;
    setM(selectedMeter);
    sessionStorage.setItem("m", selectedMeter); // Save to m sessionStorage
  };

  const handleSearch = (e) => {
    e.preventDefault(); // Prevents the page from reloading

    // Find the object that matches the selected meter value
    const selectedMeter = availableMeters.find(
      ({ meter }) => meter === parseInt(m)
    );

    if (selectedMeter) {
      setViewerState({
        type: "UPDATE_FROM_SEARCHBOX",
        payload: { id: selectedMeter.index },
      });
    }
  };

  return (
    <form className="search-box" onSubmit={handleSearch}>
      {/* Bandel dropdown */}
      <select value={bdl} onChange={handleBdlChange}>
        <option value="">Section</option>
        {Object.keys(availableBandels).map((bandel) => (
          <option key={bandel} value={bandel}>
            {bandel}
          </option>
        ))}
      </select>

      {/* Kilometer dropdown */}
      <select value={km} onChange={handleKmChange} disabled={!bdl}>
        <option value="">{bdl ? "km" : ""}</option>
        {availableKms.map((km) => (
          <option key={km} value={km}>
            {km}
          </option>
        ))}
      </select>

      {/* Meter dropdown */}
      <select value={m} onChange={handleMChange} disabled={!km}>
        <option value="">{km ? "m" : ""}</option>
        {availableMeters.map(({ meter, index }) => (
          <option key={index} value={meter}>
            {meter}
          </option>
        ))}
      </select>

      <button className="search-btn" type="submit">
        <img src={searchIcon} alt="Search" className="search" />
      </button>
    </form>
  );
};

export default SearchBoxComponent;
